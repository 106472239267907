import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Check } from 'react-feather'

import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

const About = () => {
	const data = useStaticQuery(graphql`
		query {
			aboutTop: file(relativePath: { eq: "about/about-top.png" }) {
				childImageSharp {
					fluid(maxWidth: 350) {
						...GatsbyImageSharpFluid_noBase64
					}
				}
			},
			watchVideo: file(relativePath: { eq: "about/watch-video.png" }) {
				childImageSharp {
					fluid(maxWidth: 350) {
						...GatsbyImageSharpFluid_noBase64
					}
				}
			},
			publish: file(relativePath: { eq: "about/girl-phone.png" }) {
				childImageSharp {
					fluid(maxWidth: 350) {
						...GatsbyImageSharpFluid_noBase64
					}
				}
			}
		}`
	)

	return (
		<Layout>
			<SEO title="About" />
			<div className="card mb-3 mb-md-4">
				<div className="card-img-top">
					<Img fluid={data.aboutTop.childImageSharp.fluid} />
				</div>
				<div className="card-body">
					<div className="row">
						<div className="col-md-12">
							<h1>About Us</h1>
							<p className='mb-1'>2MYNZ is a CoopTech business where users will participate in the corporate value that they help to generate</p>
							<p className='mb-1'>The technology world has given birth to some of the fastest growing companies ever. Along with that light speed growth comes astronomical valuations that have seen a new bread of billionaires. Founders and investors who have been in the right place at the right time celebrate success measured in the number of users. Those users are you and without you there is no success. CoopTech seeks to reward users and make them part of the corporation in a more meaningful way. Just like the cooperative models of the 20th century, 2MYNZ will treat users as stakeholders and reward them accordingly. In this way users, who have been pivotal to the growth of our company will be part of our success.</p>
						</div>
					</div>
				</div>
			</div>

			<div className="card about-watch mb-3 mb-md-4">
				<div className="card-body">
					<div className="row">
						<div className="col-md-7">
							<h2 className='mb-2 mb-md-3'>Product</h2>
							<h3>"Growing up everyone told me about the 'elevator pitch' and if you can't describe an idea in a minute or so you will never make it!"</h3>
							<p className='mb-1'>Being able to concisely describe a concept in under 2 minutes is a very important skill to develop and it is not always easy. 2MYNZ is designed to help you capture the essence of an idea so others can benefit from your knowledge..</p>
							<p>We have designed 2MYNZ to allow students to capture educational ideas and inspirational thoughts. Every 2MYNZ video is broken into 20 second segments that we tie together into one powerful production:</p>
							<ul className='about-watch__timeline mb-2 mb-md-0'>
								<li><Check /> Introduction</li>
								<li><Check /> Idea</li>
								<li><Check /> Example</li>
								<li><Check /> Idea</li>
								<li><Check /> Example</li>
								<li><Check /> Conclusion</li>
							</ul>
						</div>
						<div className="col-md-5">
							<div className="about-watch__img">
								<Img fluid={data.watchVideo.childImageSharp.fluid} />
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="card about-publish">
				<div className="card-body">
					<div className="row">
						<div className="col-md-7">
							<h3 className='mb-2 mb-lg-3'>What is a qualified video?</h3>
							<p className='mb-2'>In order to reach qualified status your video must go through three filters. This will happen either through your teacher, who will then publish, or via our simple publishing process:</p>
							<ul className='list-number mb-2 mb-lg-3'>
								<li>The first filter in Engagement. Once you have reached 50 likes you qualify to move to steps 2 and 3</li>
								<li>The second filter is Efficacy. Our education experts will grade your video based on its learning value</li>
								<li>The last filter is Efficiency. Our experts will grade your video based on its quality in terms of production values.</li>
							</ul>
							<p className='mb-3'>The result will be a qualified video that will form part of the 2MYNZ curriculum.</p>

							<h2>Publish your content and become an owner of 2mynz</h2>

							<h3>Unlike other social media platforms, 2MYNZ provides two benefits:</h3>
							<ul className='list-number mb-2 mb-lg-3'>
								<li>You are educating other students in a subject you know well</li>
								<li>For every qualified video you upload you will take an ownership stake in the profits and potential sale of 2MYNZ</li>
							</ul>
						</div>

						<div className="col-md-5 d-flex align-items-end">
							<div className="about-publish__img flex-grow-1">
								<Img fluid={data.publish.childImageSharp.fluid} />
							</div>
						</div>
					</div>
				</div>
			</div>


		</Layout>
	)
}

export default About
